#artifact {
    max-width: 1024px;
    margin: 0 auto 60px;
}

#artifact h1 {
    font-family: 'Bw Gradual Thin', sans-serif;
    font-weight: 100;
    font-size: 56px;
    margin: 20px 0;
    padding-left: 30px;
}

.row {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
}

.row .image,
.row .info {
    width: 50%;
    position: relative;
}

.img-wrapper,
.img-wrapper img,
.photo-wrapper,
.photo-wrapper img {
    width: 100%;
}

.photo-wrapper {
    padding: 30px;
    box-sizing: border-box;
}

.marker {
    position: relative;
    bottom: 20px;
    left: 30px;
    width: 30px;
    height: 30px;
}

.marker img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.description {
    width: 100%;
    padding: 30px;
    text-align: left;
    font-family: 'Bw Gradual Light', sans-serif;
    font-weight: 300;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
}

.description p {
    line-height: 1.5;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-right: 20px;
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }

    .row .image,
    .row .info {
        width: 100%;
    }

    .info {
        margin-top: 30px;
        padding: 0;
    }

    .description {
        padding: 15px;
    }

    #artifact h1 {
        margin: 0;
        padding: 20px;
        font-size: 40px;
    }

    .photo-wrapper {
        padding: 0;
    }
}
