.button {
    position: absolute;
    top: 22px;
    right: 50px;
    width: 20px;
    height: 20px;
    padding: 10px;
}

.button img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 600px) {
    .button {
        top: 10px;
        right: 10px;
    }
}

.menu-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
    max-width: 400px;
    height: 100vh;
    padding: 40px;
    z-index: 10;
    background: #000;
    box-sizing: border-box;
}

.menu {
    padding: 40px 0;
}

.menu-link {
    display: block;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #fff !important;
}

@media (max-width: 600px) {
    .menu-wrapper {
        width: 100%;
        max-width: 100%;
    }
}
