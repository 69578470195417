@font-face {
    font-family: 'Bw Gradual Light';
    src: url('assets/fonts/BwGradual-Light.woff2') format('woff2'),
        url('assets/fonts/BwGradual-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bw Gradual Thin';
    src: url('assets/fonts/BwGradual-Thin.woff2') format('woff2'),
        url('assets/fonts/BwGradual-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bw Gradual Bold';
    src: url('assets/fonts/BwGradual-Bold.woff2') format('woff2'),
        url('assets/fonts/BwGradual-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Bw Gradual Light', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    font-weight: 300;
}

header {
    position: relative;
}

header img {
    width: 100%;
}

h1, h2, h3 {
    font-family: 'Bw Gradual Thin', sans-serif;
    font-weight: 100;
    margin: 0 0 20px;
}

h1 {
    font-size: 38px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 22px;
}

a,
a:visited {
    color: #7F5E9E;
    text-decoration: none;
    font-family: 'Bw Gradual Bold', sans-serif;
    font-weight: 700;
}
