main {
    max-width: 960px;
    margin: 0 auto 40px;
}

section {
    padding: 30px;
    margin: 30px 0;
    text-align: left;
    border: 1px solid #000;
}

.info-wrapper {
    position: absolute;
    bottom: 20px;
    right: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 450px;
    padding: 15px;
    box-sizing: border-box;
}

.map {
    max-width: 100px;
    padding: 30px;
    margin-bottom: 15px;
    background: #fff;
    border: 1px solid #000;
}

.map p {
    text-align: center;
    font-size: 18px;
    margin: 0;
}

.lang {
    position: absolute;
    top: 30px;
    right: 100px;
}

#home #player {
    background: white;
}

#home .progress-bar {
    background: #acacac;
}

@media (max-width: 600px) {
    main {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }

    #home h1 {
        font-size: 28px;
    }

    #home h2 {
        font-size: 22px;
    }

    .info-wrapper {
        position: absolute;
        right: 0;
    }

    .map {
        padding: 15px;
    }

    .lang {
        right: 55px;
        top: 18px;
    }
}
